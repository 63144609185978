<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple bordered>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_config.fiscal_year') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomNoSetup.year_bn : roomNoSetup.year }}</b-td>
                                <b-th style="width:20%">{{ $t('org_pro.organization') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomNoSetup.org_bn : roomNoSetup.org }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_config.office_type') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomNoSetup.office_type_bn : roomNoSetup.office_type }}</b-td>
                                <b-th style="width:20%">{{ $t('org_pro.office_namel') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomNoSetup.office_bn : roomNoSetup.office }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_config.guest_house') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomNoSetup.guestHouse_bn : roomNoSetup.guestHouse }}</b-td>
                            </b-tr>
                    </b-table-simple>
                    <b-row>
                        <b-col lg="12" sm="12" class="text-center mb-4">
                            <h5 class='complain-title'>{{ $t('globalTrans.details') }}</h5>
                        </b-col>
                    </b-row>
                    <b-table-simple bordered>
                        <b-tr>
                            <b-th style="width:25%">{{ $t('elearning_config.room_type') }}</b-th>
                            <b-th style="width:25%">{{ $t('elearning_config.floor_no') }}</b-th>
                            <b-th style="width:25%">{{ $t('elearning_config.room_no') }}</b-th>
                            <b-th style="width:25%">{{ $t('globalTrans.remarks') }}</b-th>
                        </b-tr>
                        <b-tr v-for="(item,index) in roomNoSetup.room_details" :key="index">
                            <b-td style="width:20%">{{ ($i18n.locale=='en') ? $store.state.TrainingElearning.commonObj.roomTypeList.find(doc => doc.value === parseInt(item.room_type_id)).text_en : $store.state.TrainingElearning.commonObj.roomTypeList.find(doc => doc.value === parseInt(item.room_type_id)).text_bn }}</b-td>
                            <b-td style="width:30%">{{ $n(item.floor_no) }}</b-td>
                            <b-td style="width:20%">{{ (item.room_no) }}</b-td>
                            <b-td style="width:20%">{{ ($i18n.locale=='en') ? item.remarks : item.remarks_bn }}</b-td>
                        </b-tr>
                    </b-table-simple>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.roomNoSetup = this.item
  },
  data () {
    return {
      roomNoSetup: [],
      slOffset: 1
    }
  },
//   computed: {
//     localeLang () {
//       return this.$i18n.locale
//     },
//     // listData () {
//     //   const objectData = this.item
//     //   return objectData.filter(data => data.id === this.item.id && data.pump_directory_id)
//     // },
//     columns () {
//       const labels = [
//           { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
//           { label: this.$t('elearning_config.room_type'), class: 'text-left' },
//           { label: this.$t('elearning_config.floor_no'), class: 'text-left' },
//           { label: this.$t('elearning_config.room_no'), class: 'text-left' },
//           { label: this.$t('globalTrans.remarks'), class: 'text-left' }
//         ]

//       let keys = []

//       if (this.$i18n.locale === 'bn') {
//         keys = [
//           { key: 'index' },
//           { key: 'room_type_id' },
//           { key: 'floor_no' },
//           { key: 'room_no' },
//           { key: 'remarks_bn' }
//         ]
//       } else {
//         keys = [
//           { key: 'index' },
//           { key: 'room_type_id' },
//           { key: 'floor_no' },
//           { key: 'room_no' },
//           { key: 'remarks' }
//         ]
//       }

//       return labels.map((item, index) => {
//           return Object.assign(item, keys[index])
//       })
//     }
//   },
  methods: {
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
