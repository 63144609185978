<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('elearning_config.room_no_setup') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="org_id"
                >
                <template v-slot:label>
                {{ $t('org_pro.organization')}}
                </template>
                <b-form-select
                plain
                v-model="search.org_id"
                :options="orgList"
                :disabled="!$store.state.Auth.activeRoleId || $store.state.Auth.activeRoleId !== 1"
                id="org_id"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
            </b-form-group>
            </b-col>
           <b-col lg="6" md="6" sm="12" xs="12">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="guest_house_id"
                  >
                  <template v-slot:label>
                      {{ $t('elearning_config.guest_house_name') }}
                  </template>
                  <b-form-select
                  plain
                  v-model="search.guest_house_id"
                  :options="guestHouseList"
                  id="guest_house_id"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('elearning_config.room_type')"
              label-for="room_type_id"
            >
            <b-form-select
                plain
                v-model="search.room_type_id"
                :options="roomTypeList"
                id="room_type_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
                <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('elearning_config.room_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(name)="data">
                      <span class="capitalize">{{ data.item.name }}</span>
                    </template>
                    <template v-slot:cell(room_type)="data">
                      <span class="capitalize">{{ data.item.room_type }}</span>
                    </template>
                    <template v-slot:cell(room_no)="data">
                      <span class="capitalize">{{ $n(data.item.room_no) }}</span>
                    </template>
                    <template v-slot:cell(floor_no)="data">
                      <span class="capitalize">{{ $n(data.item.floor_no) }}</span>
                    </template>
                    <template v-slot:cell(attachment)="data">
                      <a v-show="data.item.attachment !== null" :href="trainingElearningServiceBaseUrl + 'download-attachment?file=uploads/config/accomodation-setup/original/' + data.item.attachment" class="btn btn-success"><i class="fa fa-cloud" aria-hidden="true"></i> {{ $t('globalTrans.attachment') }}</a>
                    </template>
                    <template v-slot:cell(status)="data">
                        <span class="badge badge-success" v-if="data.item.status === 1">{{$t('globalTrans.active')}}</span>
                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_status" title="Status" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" v-if="data.item.status === 2" @click="remove(data.item)"><i class="fa fa-toggle-off"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
    <b-modal id="modal-5" size="lg" :title="$t('elearning_config.room_no_setup')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Details :item="item" :key="id"></Details>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import FormV from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { accomodationSetupList, accomodationSetupToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import Details from './Details'

export default {
  name: 'UiDataTable',
  mixins: [ModalBaseMasterList],
  components: {
    FormV, Details
  },
  data () {
    return {
        trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
        search: {
            room_type_id: 0,
            guest_house_id: 0,
            org_id: 0
        },
        accomodationSetups: {
            room_details: [
                {
                    room_type_id: 0
                }
            ]
        },
        id: 0,
        item: '',
        roomTypeList: this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    }
  },
  computed: {
     ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    guestHouseList: function () {
      return this.$store.state.TrainingElearning.commonObj.guestHouseList.filter(item => item.status === 1)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('elearning_config.room_list') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.room_list') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('elearning_config.fiscal_year'), class: 'text-center' },
          { label: this.$t('org_pro.organization'), class: 'text-center' },
          { label: this.$t('org_pro.office_type'), class: 'text-center' },
          { label: this.$t('org_pro.office_namel'), class: 'text-center' },
          { label: this.$t('elearning_config.guest_house_name'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'year_bn' },
          { key: 'org_bn' },
          { key: 'office_type_bn' },
          { key: 'office_bn' },
          { key: 'guestHouse_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'year' },
          { key: 'org' },
          { key: 'office_type' },
          { key: 'office' },
          { key: 'guestHouse' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.guest_house_id': function (newVal, oldVal) {
        this.roomTypeList = this.getRoomTypeList(newVal)
    }
  },
  mounted () {
    this.search = Object.assign({}, this.search, {
        org_id: this.$store.state.dataFilters.orgId
    })
    core.index()
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
        this.item = item
    },
    remove (item) {
      this.changeStatus(trainingElearningServiceBaseUrl, accomodationSetupToggleStatus, item, 'training_e_learning', 'accomodationSetupList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(trainingElearningServiceBaseUrl, accomodationSetupList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org = orgObj.text_en
              orgData.org_bn = orgObj.text_bn
            } else {
              orgData.org = ''
              orgData.org_bn = ''
            }

            const officetObj = this.$store.state.commonObj.officeTypeList.find(doc => doc.value === parseInt(item.office_type_id))
            const officetData = {}
            if (typeof officetObj !== 'undefined') {
              officetData.office_type = officetObj.text_en
              officetData.office_type_bn = officetObj.text_bn
            } else {
              officetData.office_type = ''
              officetData.office_type_bn = ''
            }

            const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
              officeData.office = officeObj.text_en
              officeData.office_bn = officeObj.text_bn
            } else {
              officeData.office = ''
              officeData.office_bn = ''
            }

            const yearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
            const yearData = {}
            if (typeof yearObj !== 'undefined') {
              yearData.year = yearObj.text_en
              yearData.year_bn = yearObj.text_bn
            } else {
              yearData.year = ''
              yearData.year_bn = ''
            }

            const guestHouseObj = this.$store.state.TrainingElearning.commonObj.guestHouseList.find(doc => doc.value === parseInt(item.guest_house_id))
            const guestHouseData = {}
            if (typeof guestHouseObj !== 'undefined') {
              guestHouseData.guestHouse = guestHouseObj.text_en
              guestHouseData.guestHouse_bn = guestHouseObj.text_bn
            } else {
              guestHouseData.guestHouse = ''
              guestHouseData.guestHouse_bn = ''
            }
            // this.accomodationSetups.room_details = item.room_details.map(item2 => {
            //   const RoomTypeObj = this.$store.state.TrainingElearning.commonObj.roomTypeList.find(equip => equip.value === item2.room_type_id)
            //   const roomTypeData = { roomType_name: RoomTypeObj.text_en, roomType_name_bn: RoomTypeObj.text_bn }
            //   return Object.assign({}, item2, roomTypeData)
            // })

            return Object.assign({}, item, orgData, officetData, yearData, officeData, guestHouseData)
          })
          return listData
    },
    getRoomTypeList (houseId = null) {
        const houseList = this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
        if (houseId) {
            return houseList.filter(house => house.guest_house_id === houseId)
        }
        return houseList
    }
  }
}
</script>
